import { SiteLocale, GiftVoucherTheme } from 'src/types'

export const GIFTS_THEME: GiftVoucherTheme = 'xmas'
export const GIFTUP_SITE_ID = '94ddb1a6-90d1-4547-97dc-3ef3666c41ea'
export const GIFTUP_GROUPS_PER_LOCALE = {
  [SiteLocale.en]: '100018b4-fea8-4d12-9edc-08d893002cc6',
  [SiteLocale.de]: 'a1ba207d-9b43-4bce-b862-08d894a710bf',
  [SiteLocale.es]: 'fac70eb8-c326-41f3-b863-08d894a710bf',
}

export const STREAMS_GIFTUP_SITE_ID = '40d0518d-4ad4-4075-a25f-1ffe5d18fdf2'
export const STREAMS_GIFTUP_GROUPS_PER_LOCALE = {
  [SiteLocale.en]: 'edabc0c8-9a6d-4771-6719-08d989c08a3e',
  [SiteLocale.de]: 'e7e61737-cc45-4459-671a-08d989c08a3e',
  // There is no ES group in Streams right now, so we use the EN group
  [SiteLocale.es]: 'edabc0c8-9a6d-4771-6719-08d989c08a3e',
}

// Generated files from `scripts/giftup.ts`
export { getLessonsGiftupProducts } from './lessonsGiftupProducts'
export { getStreamsGiftupProducts } from './streamsGiftupProducts'
