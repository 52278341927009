import * as React from 'react'
import { useEffect, useRef } from 'react'
import { useLocale } from 'src/lib/I18nContext'
import { Box } from '@chatterbug/aaron'

/**
 * Docs:
 * https://help.giftup.com/category/51-checkout-customizations
 * https://help.giftup.com/article/31-customizing-the-checkout-experience
 */

const ScriptGloop = `
  (function (g, i, f, t, u, p, s) { g[u] = g[u] || function() { (g[u].q = g[u].q || []).push(arguments) }; p = i.createElement(f); p.async = 1; p.src = t; s = i.getElementsByTagName(f)[0]; s.parentNode.insertBefore(p, s); })(window, document, "script", "https://cdn.giftup.app/dist/gift-up.js", "giftup");`

export interface GiftcardPayload {
  orderId: string
  currency: string
  revenue: number
  giftCards: GiftCard[]
}

export interface GiftCard {
  id: string
  code: string
  item: string
  sku: string
  price: number
}

export type ConversionCallback = (p: GiftcardPayload) => unknown

export type NameAndEmail = {
  name: string
  email: string
}

export interface GiftupCheckoutProps {
  productId?: string
  groupId?: string
  siteId: string
  language?: string
  purchaser?: NameAndEmail
  recipient?: NameAndEmail
  whoFor?: 'yourself' | 'someoneelse' | 'onlyme' | 'onlymesomeoneelse'
  promoCode?: string
  step?: 'details' | 'payment'
  noArtwork?: boolean
  noCustomValue?: boolean
  noGroups?: boolean
  noUngroupedItems?: boolean
  onConversion?: ConversionCallback
  onStepChanged?: (stepName: string) => void
}

// The list should be like in settings: https://giftup.app/settings/business
// Nevertheless Giftup remembers if user clicks on language icons in the footer and starts ignoring our `data-language`.
// So please test in Incognito browser to validate if our code works.
const CB_LANG_TO_GIFTUP_LANG: Record<string, string> = {
  en: 'en-US',
  de: 'de-DE',
  es: 'es-ES',
}

export const GiftupCheckout: React.FC<GiftupCheckoutProps> = ({
  productId,
  groupId,
  siteId,
  language,
  purchaser,
  recipient,
  whoFor,
  promoCode,
  step,
  noArtwork = false,
  noCustomValue = false,
  noGroups = false,
  noUngroupedItems = false,
  onConversion,
  onStepChanged,
}) => {
  const hostDiv = useRef<HTMLDivElement>(null)
  const prevProductId = useRef<string | null>(null)
  const locale = useLocale()

  // NB: Because we only get one shot to register this callback with
  // Giftup, we're gonna shove off whatever the latest React callback
  // for these events are in a Ref
  const convRef = useRef(onConversion)
  const stepRef = useRef(onStepChanged)
  convRef.current = onConversion
  stepRef.current = onStepChanged

  if (productId) {
    if (!prevProductId.current) {
      prevProductId.current = productId
    } else {
      throw new Error(
        "GiftUp doesn't allow us to change the Product IDs without reloading the page"
      )
    }
  }

  useEffect(() => {
    // NB: Make sure our `div` actually, for reals exists, before we sic
    // Giftup on it
    if (!hostDiv.current) return

    // eslint-disable-next-line no-eval
    eval(ScriptGloop)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const wnd: any = window
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const giftup: any = wnd.giftup

    giftup('conversion', (c: GiftcardPayload) => convRef.current?.(c))
    giftup('page', (p: string) => stepRef.current?.(p))
  }, [hostDiv])

  const lang =
    CB_LANG_TO_GIFTUP_LANG[language ?? locale] || CB_LANG_TO_GIFTUP_LANG.en

  return (
    <Box
      className="gift-up-target"
      data-site-id={siteId}
      data-hide-artwork={String(noArtwork)}
      data-hide-custom-value={String(noCustomValue)}
      data-hide-groups={String(noGroups)}
      data-hide-ungrouped-items={String(noUngroupedItems)}
      data-platform="Other"
      data-product-id={productId || null}
      data-group-id={groupId || null}
      data-promo-code={promoCode}
      data-purchaser-name={purchaser?.name}
      data-purchaser-email={purchaser?.email}
      data-language={lang}
      data-recipient-name={recipient?.name}
      data-recipient-email={recipient?.email}
      data-step={step}
      data-who-for={whoFor}
      ref={hostDiv}
    />
  )
}
