import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { PageProps } from 'gatsby'
import queryString from 'query-string'
import { Box, Flex } from '@chatterbug/aaron'
import Layout from 'src/components/Layout/Layout'
import Head from 'src/components/Head/Head'
import PageContainer from 'src/components/PageContainer/PageContainer'
import { GiftupCheckout } from 'src/components/GiftupCheckout/GiftupCheckout'
import {
  getStreamsGiftupProducts,
  STREAMS_GIFTUP_SITE_ID,
  STREAMS_GIFTUP_GROUPS_PER_LOCALE,
} from 'src/data/vouchers/gifts'
import { useLocale } from 'src/lib/I18nContext'
import { PageAccentVariant, PageContext } from 'src/types'
import GiftCard from 'src/components/GiftCard/GiftCard'
import GiftVoucherGradientBackground from 'src/components/GradientBackground/GiftVoucherGradientBackground'
import StreamsHeader from 'src/components/StreamsHeader/StreamsHeader'

const VouchersCheckoutPage: React.FC<PageProps<unknown, PageContext>> = ({
  pageContext,
  location,
}) => {
  const [isReady, setIsReady] = useState(false)
  const t = useTranslate()
  const locale = useLocale()
  const params = queryString.parse(location.search)
  const giftProducts = getStreamsGiftupProducts(locale, t)
  const giftGroupId = STREAMS_GIFTUP_GROUPS_PER_LOCALE[locale]
  const DEFAULT_SHOWCASE_INFO = {
    ...giftProducts[1],
    label: null,
  }
  const currentProduct = giftProducts.find((item) => item.name === params?.gift)

  useEffect(() => {
    if (typeof window === 'undefined') {
      // skip during gatsby build
      return
    }

    if (!isReady) {
      setIsReady(true)
    }
  }, [isReady, setIsReady])

  return (
    <Layout pageContext={pageContext} hideSiteHeader>
      <Head
        title={t('Vouchers Checkout')}
        description={t('gifts_hero.sub_header')}
        socialImage={`/assets/global/og-images/gifts/streams/${locale}.jpg`}
        pageContext={pageContext}
      />
      <GiftVoucherGradientBackground
        variant={PageAccentVariant.blue}
        sx={{ overflow: 'hidden' }}
      >
        <PageContainer>
          <Box pb={60}>
            <StreamsHeader canonicalPath={pageContext.canonicalPath} />
          </Box>
          <Flex
            justifyContent="center"
            sx={{
              position: 'relative',
              zIndex: 3,
            }}
          >
            <GiftCard
              product={currentProduct?.product || DEFAULT_SHOWCASE_INFO.product}
              giftCoverImageHeight={250}
              description={
                currentProduct?.numberOfLessons
                  ? t(
                      '%{smart_count} Live Lesson |||| %{smart_count} Live Lessons',
                      {
                        smart_count: currentProduct?.numberOfLessons,
                      }
                    )
                  : t('Gift Voucher')
              }
              giftSize={
                currentProduct?.giftSize || DEFAULT_SHOWCASE_INFO.giftSize
              }
            />
          </Flex>
          {isReady && (
            <Box mb={60}>
              <GiftupCheckout
                siteId={STREAMS_GIFTUP_SITE_ID}
                noArtwork
                noUngroupedItems
                productId={currentProduct?.productId}
                groupId={giftGroupId}
              />
            </Box>
          )}
        </PageContainer>
      </GiftVoucherGradientBackground>
    </Layout>
  )
}

export default VouchersCheckoutPage
